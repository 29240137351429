@tailwind base;
@tailwind components;
@import './subscribe-form.css';
@import './quiz.css';
@import './prism.css';
@import './focus-visible.css';
@import './nprogress.css';
@tailwind utilities;
@import './fonts/index.css';

html,
body,
#root {
  color-scheme: dark;
}

.no-marker {
  ::-webkit-details-marker {
    display: none;
  }
}

mux-player {
  aspect-ratio: 16 / 9;
  width: 100%;
  height: auto;
  @apply relative overflow-hidden;
}

.hero-animation-h1 {
  animation: HeroAnimationH1 3s 0.2s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
}

.hero-animation-p {
  animation: HeroAnimationP 4s 0.4s forwards cubic-bezier(0.2, 0.8, 0.2, 1);
  opacity: 0;
}

@keyframes HeroAnimationBackground {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(30px);
  }
}

@keyframes HeroAnimationH1 {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes HeroAnimationP {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
