@font-face {
  font-family: 'Sicret Mono';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/sicret-mono.woff2') format('woff2'),
    url('/fonts/sicret-mono.woff') format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100 900;
  font-display: optional;
  src: url('/fonts/ibm-plex-sans-var.woff2') format('woff2');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100 900;
  font-display: optional;
  src: url('/fonts/ibm-plex-sans-var-italic.woff2') format('woff2');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url('/fonts/ibm-plex-mono-var.woff2') format('woff2');
}

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: optional;
  src: url('/fonts/ibm-plex-mono-var-italic.woff2') format('woff2');
}
