/* ——— QUIZ ——— */
[data-sr-quiz] {
  @apply flex w-full flex-col items-center justify-center p-5;
  nav {
    @apply bg-gray-900;
    span {
      @apply text-gray-500;
    }
  }
}

/* ——— QUESTION ——— */
[data-sr-quiz-question] {
  @apply mx-auto w-full max-w-screen-sm;

  /* ——— HEADER ——— */
  [data-sr-quiz-question-header] {
    @apply prose w-full max-w-none pb-6 prose-p:font-medium sm:prose-lg;

    pre {
      @apply p-0;
    }
  }

  /* ——— BODY ——— */
  [data-sr-quiz-question-body] {
    @apply list-none;
    & > li {
      @apply list-none;
    }
  }

  /* ——— CHOICES ——— */
  [data-sr-quiz-question-choices] {
    @apply flex flex-col space-y-4;
  }

  /* ——— CHOICE ——— */
  [data-sr-quiz-question-choice] {
    img {
      @apply w-full;
    }
    label {
      @apply flex  items-center rounded-md border border-transparent bg-gray-900 p-5 shadow-2xl shadow-gray-500/20 transition-all duration-100 ease-in-out;
    }
    input {
      @apply mr-2 accent-blue-500;
    }
    p {
      @apply flex-grow leading-tight;
    }
    span {
      @apply rounded-md px-2 py-0.5 pb-1 text-xs font-medium leading-none;
    }
  }

  [data-sr-quiz-question-choice=''] {
    label {
      @apply cursor-pointer;
    }
  }

  [data-sr-quiz-question-choice='correct'] {
    label {
      @apply border-teal-500;
    }
    span {
      @apply bg-teal-600 text-white;
    }
  }

  [data-sr-quiz-question-choice='incorrect'] {
    label {
      @apply cursor-default border-pink-500;
    }
    span {
      @apply bg-pink-500 text-white;
    }
  }

  /* ——— TEXTAREA ——— */
  [data-sr-quiz-question-input] {
    label {
      @apply font-medium;
    }
    textarea {
      @apply w-full rounded-lg border border-gray-200 bg-gray-100 p-3;
    }
  }
  [data-sr-quiz-question-input='error'] {
    textarea {
      @apply border-pink-500;
    }
  }

  /* ——— ANSWER ——— */
  [data-sr-quiz-question-answer] {
    @apply prose mt-4 max-w-none rounded-lg bg-gray-800 p-5 pt-5 prose-code:rounded-md prose-code:bg-gray-800 prose-code:px-1 prose-code:py-0.5 prose-code:text-xs prose-code:font-bold sm:prose-lg sm:prose-code:text-sm;
    pre {
      @apply mb-5 p-0;
    }
  }

  /* ——— FOOTER ——— */
  [data-sr-quiz-question-footer] {
    @apply prose mt-5 max-w-none px-5 py-1 text-center;
  }

  /* —— SUBMIT —— */
  [data-sr-button] {
    @apply mt-5 inline-flex cursor-pointer items-center rounded-md border-none bg-brand px-6 py-3 text-lg font-semibold leading-6 text-white shadow-xl transition duration-300 ease-in-out hover:scale-105 hover:bg-blue-500 hover:shadow-xl;
    svg {
      @apply h-6 w-6 animate-spin text-indigo-300;
    }
  }

  [data-sr-button][disabled] {
    @apply cursor-not-allowed;
  }
}
