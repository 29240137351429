#primary-newsletter-cta {
  [data-sr-convertkit-subscribe-form] {
    @apply mx-auto flex w-full max-w-[400px] flex-col gap-6;
    [data-sr-input] {
      @apply block w-full rounded-lg bg-white px-5 py-4 text-black focus:bg-white;
    }
    [data-sr-input-label] {
      @apply inline-block pb-1 font-medium;
    }
    [data-sr-input-asterisk] {
      @apply opacity-50;
    }
    [data-sr-button] {
      @apply mt-2 flex w-full items-center justify-center overflow-clip rounded-md bg-brand p-5 font-heading text-lg font-bold tracking-wide text-white shadow-xl transition focus:outline-white hover:brightness-125 sm:text-xl;

      svg {
        @apply h-6 w-6;
      }
    }
  }
}

#video-overlay {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full max-w-sm flex-col gap-4 text-left;
    [data-sr-input] {
      @apply block w-full rounded-lg bg-white px-5 py-4 text-black focus:bg-white;
    }
    [data-sr-input-label] {
      @apply inline-block pb-1 font-medium;
    }
    [data-sr-input-asterisk] {
      @apply opacity-50;
    }
    [data-sr-button] {
      @apply mt-2 flex w-full items-center justify-center overflow-clip rounded-md bg-brand p-5 font-heading text-lg font-bold tracking-wide text-white shadow-xl transition focus:outline-white hover:brightness-125 sm:text-xl;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}

#tip {
  [data-sr-convertkit-subscribe-form] {
    @apply flex w-full flex-col gap-2 text-left md:flex-row;
    [data-sr-input-wrapper] {
      @apply w-full;
    }
    [data-sr-input-wrapper]:first-of-type {
      @apply md:w-2/3;
    }
    [data-sr-input] {
      @apply block w-full rounded-lg bg-white px-5 py-3 text-black focus:bg-white;
    }

    [data-sr-input-label] {
      @apply sr-only;
    }
    [data-sr-button] {
      @apply relative flex flex-shrink-0 items-center justify-center rounded-md bg-brand py-2 px-5 font-semibold text-white shadow-2xl shadow-cyan-900/50 transition focus-visible:ring-white hover:brightness-125;
      svg {
        @apply h-7 w-7;
      }
    }
    [data-sr-button][disabled] {
    }
  }
  [data-sr-convertkit-subscribe-form='success'] {
    p {
      @apply py-2 text-center;
    }
  }
  [data-sr-convertkit-subscribe-form='error'] {
    p {
      @apply py-2 text-center;
    }
  }
}
